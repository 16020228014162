html {
    font-family: 'Poppins', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #000;
    position: relative;
    font-weight: 400;
    font-size: 12px;
}

    body > * {
        background-color: #fff;
    }

/*.fixed-body {
    margin-top: 80px;
}
*/
nav.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 149px;
    padding: 0px;
    z-index: 10010;
    transition: background-color 1s ease, opacity 1s ease;
    opacity: 1;
}

    nav.navbar .container-fluid {
        padding: 0px !important;
        position: relative;
    }

.fixed-body nav.navbar {
    background-color: #000;
    height: 80px;
}

.scroll-down-body nav.navbar {
    opacity: 0;
    z-index: -1;
}

.nav-link {
    color: #fff;
}

.custom-link {
    color: #fff !important;
    position: relative;
    display: inline-block;
    margin-right: 19px;
    margin-left: 19px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 2px !important;
    font-weight: 600;
    font-size: 18px;
}
    .custom-link:hover, .custom-link:focus {
        color: #fff;
    }

.custom-link:hover::before {
    content: "";
    position: absolute;
    height: 3px;
    width: 50%;
    bottom: 1px;
    left: 0;
    background-color: #fff;
}

    .custom-link.active::before {
        content: "";
        position: absolute;
        height: 3px;
        bottom: 1px;
        left: 0;
        background-color: #fff;
        width: 100%;
    }

.mobile-link {
    padding: 0px !important;
    margin: 0px !important;
}


.custom-call {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Source Sans 3', sans-serif !important;
    border-radius: 40px;
    background: #000;
    text-align: center;
    padding: 8px 24px !important;
    line-height: 24px;
}
    .custom-call:hover, .custom-call:focus {
        color: #fff;
    }

.fixed-body .custom-call {
    color: #000;
    background: #fff;
}
    .fixed-body .custom-call:hover, .fixed-body .custom-call:focus {
        color: #000;
    }

img.navbar-logo {
    width: 48px;
    height: 48px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    top: 0;
}

.fixed-body nav.navbar img.navbar-logo {
    opacity: 1;
}

img.navbar-logo-large {
    width: 124px;
    height: 48px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    top: 0;
}

.fixed-body nav.navbar img.navbar-logo-large {
    opacity: 0;
}

.nav-logo-containter {
    width: 124px;
}

.fixed-body .nav-logo-containter {
    width: 48px;
}

.navbar-icon-container {
    width: 48px;
    height: 48px;
}

img.navbar-icon {
    width: 48px;
    height: 48px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    top: 0;
    cursor: pointer;
}

.fixed-body nav.navbar img.navbar-icon {
    opacity: 1;
}

img.navbar-icon.fixed {
    opacity: 1;
}

.fixed-body nav.navbar img.navbar-icon.fixed {
    opacity: 0;
}

.fake-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 10020;
    top: 0;
    left: 0;
}

.fake-overlay-nav {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 10020;
    top: 0;
    left: 0;
    opacity: .5;
}

.overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
}

.full-page-image-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.full-page-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.preview-plan-image {
    display: flex;
    align-items: center;
}
    .preview-plan-image .full-page-image {
        max-width: 400px;
        max-height: 454px;
        margin: auto;
    }

.plan-image {
    display: flex;
    align-items: center;
}

    .plan-image .auto-page-image {
        max-width: 1440px !important;
        max-height: 960px !important;
        margin: auto;
    }

.auto-page-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    max-height: 100vh;
}

.line {
    border: 3px solid #000;
    width: 30px;
}
.d-desktop, .d-desktop-4k {
    display: none;
}
.d-tablet, .d-tablet-desktop {
    display: none;
}
.d-mobile, .d-mobile-tablet {
    display: block;
}

a {
    cursor: pointer;
    text-decoration: none !important;
    color: #000;
}

.terms a {
    color: #0d6efd;
}

.text-semibold {
    font-weight: 600;
}

.text-bold {
    font-weight: 700;
}

.click {
    cursor: pointer;
}

.sidebar {
    background-color: #000;
    color: #fff;
    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    top: 0;
    bottom: 0;
    width: 100%;
    left: -100%;
    box-shadow: 3px 0px 8px 1px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    z-index: 10020;
}

    .sidebar.opened {
        left: 0;
    }

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;   
    margin-top: 48px;
    margin-bottom: 48px;
}

    .sidebar-header img {
        width: 48px;
        height: auto;
        cursor: pointer;
    }
    .sidebar-header img.logo {
        width: 124px;
    }

.sidebar .nav-link {
    font-size: 28px;
    padding-bottom: 4px !important;
    margin: 16px 0px 16px 0px;
}

.sidebar .nav-link:hover, .sidebar .nav-link:focus {
    color: #fff;
}

.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 48px;
    left: 0;
}

button {
    border-radius: 40px !important;
    padding: 10px 40px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: 'Source Sans 3', sans-serif !important;
}

.carousel-control-next, .carousel-control-prev {
    opacity: 1;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    background-image: url(./media/arrow-button-white.svg);
}
.carousel-control-prev-icon {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.carousel-indicators {
    left: unset;
    top: 0;
    bottom: 0;
    margin: auto 0px !important;
    flex-direction: column;
}

.carousel-indicators button {
    border-radius: 0px !important;
    padding: 0px !important;
    width: 3px !important;
    height: 30px !important;
    border: 0px !important;
    position: relative;
}
    .carousel-indicators button:first-of-type::after {
        content: attr(aria-label) !important;
        position: absolute;
        bottom: 30px;
        left: 0;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        text-indent: 0px !important;
        transform: translateX(-50%) rotate(90deg);
        -webkit-transform: translateX(-50%) rotate(90deg);
    }

    .carousel-indicators button:last-of-type::after {
        content: attr(aria-label) !important;
        position: absolute;
        top: 30px;
        left: 0;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        text-indent: 0px !important;
        transform: translateX(-50%) rotate(90deg);
        -webkit-transform: translateX(-50%) rotate(90deg);
    }

.carousel.slide, .carousel-inner, .carousel-item {
    height: 100vh;
    width: 100%;
    z-index: 0;
}
.fixed-body .carousel.slide, .fixed-body .carousel-inner, .fixed-body .carousel-item {
    height: calc(100vh - 80px);
}

    .carousel-item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

button.btn.btn-info {
    background: #bc8748;
    border: #bc8748;
    color: #fff;
}
    button.btn.btn-info:hover, button.btn.btn-info:focus {
        background: #be9966;
        border: #be9966;
        color: #fff;
    }

.btn-dark {
    background-color: #000;
}
    .btn-dark:hover {
        background-color: #1f1f1f;
    }

.section {
    height: 100vh;
    position: relative;
}

.empty-section {
    height: 100vh;
    width: 100%;
}

/*.fixed-body .section {
    height: calc(100vh - 80px);
}*/

.form-title {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
}

.paragraph {
    max-width: 280px;
}
.paragraph-medium {
    max-width: 380px;
}
.paragraph-100 {
    max-width: 100%;
}


    .paragraph > div {
        margin: 0px 0px 38px 0px;
    }

        .paragraph > div:last-of-type {
            margin-bottom: 0px;
        }

.paragraph-padding {
    padding: 48px 0px 48px 0px;
}

.paragraph-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paragraph-mobile-center {
    padding: 48px 0px 48px 0px;
}

.paragraph-vertical {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.paragraph-title {
    color: #000;
    font-weight: 700;
    font-size: 22px;
}

.paragraph-text {
    color: #000;
    font-weight: 400;
    font-size: 16px;
}

.img-paragraph-title {
    color: #fff;
    font-weight: 700;
    font-size: 40px;
}

.img-paragraph-subtitle {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
}

    .paragraph-text.bold {
        font-weight: 600;
    }


.image-title {
    color: #fff;
    font-weight: 600;
    font-size: 28px;
    position: absolute;
    bottom: 28px;
    max-width: 200px;
}
    .image-title.home {
        bottom: 110px;
    }
    .image-title.small-img, .view-button-right {
        bottom: 28px;
    }
    .image-title.apartments-types-title {
        bottom: 24px !important;
        left: 24px !important;
    }

.image-text-container {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.image-text-overlay-container {
    position: relative;
}

.image-full-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10040;
}

.modal-header {
    border-bottom: none !important
}

.modal-footer {
    border-top: none !important
}

.modal-title {
    font-family: 'Poppins', sans-serif !important;
    color: #fff;
    font-weight: 700;
    font-size: 22px;
}
.modal-content {
    background: #000;
}

.modal-body {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
}
.k-loading-overlay {
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.k-loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    text-align: center;
    z-index: 999999;
}

.k-loading-img {
    display: block;
    height: 70px;
    margin: auto;
}

.k-loading-txt {
    display: block;
    color: #fff;
}

.email-form {
    background-color: #000;
    position: fixed;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    top: 0px;
    bottom: 0px;
    width: 100%;
    left: -100%;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    z-index: 10030;
    display: flex;
    flex-direction: column;
}
    .email-form .row {
        margin: auto 0px;
    }
        .email-form .row:last-of-type {
            margin-bottom: 48px;
        }

    .email-form.opened {
        left: 0;
    }
    .email-form .m-field {
        margin-bottom: 28px;
    }

.mobile-email-btn {
    position: absolute;
    bottom: 28px;
}

.form-field input, .form-field input:focus {
    background: transparent !important;
    border: none;
    border-bottom: 1px solid #a7a9aa;
    border-radius: 0px !important;
    color: #fff !important;
    box-shadow: none !important;
    padding: 0px !important;
    font-size: 12px !important;
    height: 30px !important;
    min-height: 30px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #000 inset !important;
    -webkit-text-fill-color: #fff;
}

.form-field label {
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 0px !important;
    border: none !important;
    transform: none !important;
    transition: all .4s ease-in-out;
    top: 6px !important;
    left: 0;
}
    .form-field label::after {
        background: transparent !important;
        border: none !important;
    }

.form-field input:focus, .form-field input.focus {
    padding-right: 55px !important;
}

    .form-field input:focus ~ label, .form-field input.focus ~ label {
        left: 100% !important;
        transform: translateX(-100%) !important;
    }

.form-field.invalid input {
    border-bottom: 1px solid #b72025 !important;
}
.form-field.invalid label {
    color: #b72025 !important;
}

.form-check input {
    border: 1px solid #a7a9aa;
    width: 20px;
    height: 20px;
    background-color: transparent;
    box-shadow: none !important;
    cursor: pointer;
}
    .form-check input:checked, .form-check input:focus {
        border: 1px solid #a7a9aa;
        background-color: transparent;
    }

    .form-check input:checked {
        background-image: url('media/checked-icon.svg');
    }

.form-check label {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 8px !important;
    border: none !important;
    margin-top: 0.25em;
}

.form-check .error-field {
    padding-left: 8px !important;
}

.error-field img {
    width: 16px;
    height: 16px;
}
.error-field span {
    color: #b72025;
    font-size: 12px;
    font-weight: 400;
    padding-left: 6px;
}

.align-p { padding: 0px 16px !important; }
.align-np { margin: 0px -16px !important; }
.align-l { left: 16px !important; }
.align-r, .carousel-indicators { right: 16px !important; }
.align-b { bottom: 16px !important; }

.element-icon {
    align-items: center;
    display: none;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 40px;
    width: max-content;
}
    .element-icon img {
        width: 22px;
        height: 23px;
        object-fit: contain;
    }

.element-icon-br {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.element-icon-tl {
    position: absolute;
    left: 0px;
    top: 0px;
}

.element-icon-tr-40 {
    position: absolute;
    right: 0px;
    top: 40px;
}


.footer {
    margin-top: 108px;
}

    .footer img.option {
        width: 48px;
        height: auto;
        cursor: pointer;
    }

    .footer img.logo {
        width: 80px;
        height: auto;
        cursor: pointer;
    }

.footer {
    color: #000;
    font-weight: 400;
    font-size: 16px;
}

.footer .footer-title {
    color: #000;
    font-weight: 700;
    font-size: 18px;
}
     .list-group-item {
        border: 0px !important;
        padding-left: 0px;
        padding-right: 0px;
    }

.footer-menu {
    padding-top: 86px;
    padding-bottom: 300px;
    border-bottom: 2px solid #000;
}

.footer-menu-none {
    padding-top: 86px;
    padding-bottom: 0px;
}

.footer-menu-none > div {
    display: none;
}

.footer-policies {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 90px 0px;   
}

    .footer-policies a, .footer-policies span {
        color: #777777;
        font-size: 14px;
        font-weight: 400;
    }

.img-revert {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.apartments-types-container {
    padding: 16px 16px 0px 16px;
    background-color: #000;
}

.full-page-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
    background-image: linear-gradient(to bottom, transparent, #000);
}

.view-button-right {
    position: absolute;
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.apartments-types-title img.view-button {
    width: 48px;
    height: auto;
    cursor: pointer;
    transition: opacity .5s ease-in-out;
    opacity: 0;
}

.apartments-types-title.active img.view-button {
    opacity: 1;
}

.apartments-types-container .col {
    cursor: pointer;
    padding: 0px 0px 16px 0px;
}

    .apartments-types-container .col > div.full-page-image-container {
        padding: 24px;
        background-color: #fff;
    }

.apartments-types-title > div {
    padding-top: 12px;
}

.transition-container {
    transition: opacity .5s ease-in-out;
    opacity: 0;
}
    .transition-container.active {
        opacity: 1;
    }

.sold-out-image {
    z-index: 10;
    cursor: pointer;
}

.sold-out-text {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    cursor: initial;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
    .sold-out-text span {
        position: unset !important;
    }

.apartment-details-container {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: absolute;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    right: 16px;
    padding: 38px 38px 38px 38px;
    z-index: 0;
}


    .apartment-details-container .title {
        font-size: 28px;
    }
    .apartment-details-container > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid #a7a9aa;
    }

        .apartment-details-container > div:first-of-type {
            padding-bottom: 38px;
            border-bottom: 0px;
        }

video {
    width: 100%;
    height: auto;
}

.cookies-container {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    bottom: 28px;
}
    .cookies-container > div {
        background: #000;
        max-width: 480px;
        color: #fff;
        padding: 22px 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10050;
        gap: 18px;
    }

    .cookies-container img {
        width: 48px;
        height: 48px;
        cursor: pointer;
    }
    .cookies-container a {
        text-decoration: underline !important;
    }

@media screen and (max-width: 767px) {
    .paragraph-mobile-center {
        padding: 48px 0px 48px 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        align-items: center;
    }

    .paragraph-medium, .paragraph-mobile-center {
        max-width: 328px;
    }

    .image-text-overlay-container {
        height: calc(100vh - 80px);
    }
        .image-text-overlay-container img.auto-page-image {
            height: 100%;
        }

    .footer {
        margin-top: 48px;
    }
    .footer img.logo {
        width: 48px;
    }

    .footer-menu {
        padding-top: 24px;
        padding-bottom:24px;
    }

    .footer-menu-none {
        padding-top: 24px;
        padding-bottom: 0px;
    }

    .footer .list-group {
        margin-bottom: 24px;
    }
    .footer-policies {
        padding: 48px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .mobile-align-np {
        padding: 0px !important;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .apartments-types-container .col{
        padding-right: 8px;
        padding-left: 8px;
    }
        .apartments-types-container .col:nth-child(2n) {
            padding-right: 0px;
        }
        .apartments-types-container .col:nth-child(2n+1) {
            padding-left: 0px;
        }
}

/* tablet */
@media screen and (min-width: 768px) {
    .d-tablet, .d-tablet-desktop {
        display: initial;
    }

    .d-mobile {
        display: none;
    }

    img.navbar-logo-large {
        width: 187px;
        height: 73px;
    }
    .nav-logo-containter {
        width: 187px;
    }

    .align-p { padding: 0px 32px !important; }
    .align-np { margin: 0px -32px !important; }
    .align-l { left: 32px !important; }
    .align-r, .carousel-indicators { right: 32px !important; }
    .align-b { bottom: 32px !important; }

    .image-title {
        font-size: 28px;
        max-width: 200px;
        bottom: 86px;
    }
        .image-title.home {
            bottom: 172px;
        }
        .image-title.small-img, .view-button-right {
            bottom: 48px;
        }
        .image-title.apartments-types-title {
            bottom: 24px !important;
            left: 24px !important;
        }

    .mobile-email-btn {
        position: absolute;
        bottom: 86px;
    }

    .cookies-container {
        bottom: 86px;
    }

    .paragraph-mobile-center {
        padding: 0px;
    }
    .paragraph-padding {
        padding: 84px 0px 84px 0px;
    }
    .element-icon {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .image-text-container {
        width: 50%;
    }
    .paragraph-title {
        font-size: 28px;
    }

    .paragraph-text {
        font-size: 18px;
    }

    .img-paragraph-title {
        font-size: 40px;
    }

    .img-paragraph-subtitle {
        font-size: 22px;
    }

    .apartment-details-container {
        right: unset;
        width: 380px;
        padding: 60px 38px 38px 38px;
    }
        .apartment-details-container > div:first-of-type {
            padding-bottom: 70px;
        }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .apartments-types-container {
        padding: 24px 32px 8px 32px;
    }

        .apartments-types-container .col {
            padding: 0px 12px 24px 12px;
        }

            .apartments-types-container .col:nth-child(3n+1) {
                padding-left: 0px;
            }

            .apartments-types-container .col:nth-child(3n) {
                padding-right: 0px;
            }
}

@media screen and (min-width: 992px) and (max-width: 1439px) {
    .apartments-types-container {
        padding: 24px 32px 8px 32px;
    }

        .apartments-types-container .col {
            padding: 0px 12px 24px 12px;
        }

            .apartments-types-container .col:nth-child(3n+1) {
                padding-left: 0px;
            }

            .apartments-types-container .col:nth-child(3n) {
                padding-right: 0px;
            }
}

/* desktop */
@media screen and (min-width: 1024px) {
    .d-desktop {
        display: block;
    }

    .d-tablet, .d-mobile-tablet {
        display: none;
    }

    .fixed-body .nav-logo-containter {
        width: 40px;
    }

    img.navbar-logo {
        width: 40px;
        height: 37px;
    }

    .image-title, .image-title.home, .image-title.small-img, .image-title.apartments-types-title, .view-button-right {
        font-size: 40px;
        max-width: 280px;
        bottom: 86px;
    }

        .image-title.apartments-types-title {
            font-size: 28px;
            bottom: 24px !important;
            left: 24px !important;
        }


    .section-fixed .image-title, .section-fixed .email-form {
        position: fixed;
    }

    .email-form {
        position: absolute;
        top: unset;
        left: unset;
        right: 0px;
        bottom: 0px;
        width: 430px;
        height: auto;
        padding: 60px 38px 38px 38px;
        background: rgba(0, 0, 0, 0.40);
        backdrop-filter: blur(10px);
        -webkit-transition: all .2s ease;
        -o-transition: unset;
        transition: unset;
        z-index: unset;
    }

        .email-form .sidebar-header, .email-form .sidebar-footer {
            display: none;
        }

        .email-form .row {
            margin: 0px 0px 28px 0px;
        }
            .email-form .row:last-of-type {
                margin-bottom: 0px;
            }

    .element-icon {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }

    .apartment-details-container {
        font-size: 18px;
    }
        .apartment-details-container .title {
            font-size: 40px;
        }

    .cookies-container {
        bottom: 38px;
    }
}

@media screen and (min-width: 1440px){
    .align-p { padding: 0px 96px !important; }
    .align-np { margin: 0px -96px !important; }
    .align-l { left: 96px !important; }
    .align-r, .carousel-indicators { right: 96px !important; }
    .align-b { bottom: 96px !important; }

    .section {
        height: unset;
        /*min-height: 100vh;*/
        position: relative;
    }

    .fixed-body .section {
        height: unset;
       /* min-height: calc(100vh - 80px);*/
    }

    .carousel.slide, .carousel-inner, .carousel-item {
        height: unset;
        min-height: 100vh;
    }

    .fixed-body .carousel.slide, .fixed-body .carousel-inner, .fixed-body .carousel-item {
        height: unset;
        min-height: calc(100vh - 80px);
    }

    .auto-page-image {
        width: 100%;
        height: auto;
        object-fit: unset;
        object-position: unset;
        max-height: unset;
    }

    .d-desktop-4k { display: block; }
    .element-icon {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }
    .apartments-types-container {
        padding: 24px 96px 84px 96px;
    }

        .apartments-types-container .col {
            padding: 0px 12px 24px 12px;
        }

            .apartments-types-container .col:nth-child(3n+1) {
                padding-left: 0px;
            }

            .apartments-types-container .col:nth-child(3n) {
                padding-right: 0px;
            }
}
